import type { CountryType, Nullable } from "../../@types/generic";
import type { PageParameters } from "./routeTypes";

type Props = {
    parameters: PageParameters;
    searchParameters?: Record<string, string>;
    country: CountryType;
};

export const getRoute = ({
    parameters,
    searchParameters,
    country,
}: Props): string => {
    if (country === "pl") {
        return getRoutePL(parameters);
    }

    return getRouteDACH(parameters, searchParameters);
};

const getRouteDACH = (
    parameters: PageParameters,
    searchParameters?: Record<string, string>
): string => {
    switch (parameters.pageName) {
        case "compliance":
            return "/compliance/";
        case "home":
            return "/";
        case "forecastMultiple":
            return `/wetter_aktuell/wettervorhersage/${parameters.daysMultiple.toString()}_tagesvorhersage/${parameters.seoString}/${parameters.code}.html`;
        case "forecastWeekend":
            return `/wetter_aktuell/wettervorhersage/wochenend_vorhersage/${parameters.seoString}/${parameters.code}.html`;
        case "forecastToday":
            return `/${parameters.seoString}/${parameters.code}.html`;
        case "forecastTomorrow":
            return `/wetter_aktuell/wettervorhersage/morgen/${parameters.seoString}/${parameters.code}.html`;
        case "forecastSingle":
            return `/wetter_aktuell/wettervorhersage/in-${parameters.daysSingle.toString()}-tagen/${parameters.seoString}/${parameters.code}.html`;
        case "forecastHistory":
            return `/wetter_aktuell/rueckblick/${parameters.seoString}/${parameters.code}.html`;
        case "skiAreaList":
            return "/skigebiete/";
        case "skiAreaDetail":
            return `/skigebiete/${parameters.countryName}/${parameters.name}-${parameters.id}/${searchParameters ? `?${new URLSearchParams(searchParameters).toString()}` : ""}`;
        case "skiAreaDetailWeatherAndSnow":
            return `/skigebiete/${parameters.countryName}/${parameters.name}-${parameters.id}/wetter-schnee/${searchParameters ? `?${new URLSearchParams(searchParameters).toString()}` : ""}`;
        case "skiAreaDetailWeatherAndSnowRadar":
            return `/skigebiete/${parameters.countryName}/${parameters.name}-${
                parameters.id
            }/wetter-schnee/schneeradar/?${new URLSearchParams({
                variant: "ski-areas",
            }).toString()}`;
        case "skiAreaDetailSlopesAndLifts":
            return `/skigebiete/${parameters.countryName}/${parameters.name}-${parameters.id}/pisten-lifte/${searchParameters ? `?${new URLSearchParams(searchParameters).toString()}` : ""}`;
        case "healthPollenLandingpage":
            return "/gesundheit/pollenflug/";
        case "healthPollenLocationPage":
            return `/gesundheit/pollenflug/${parameters.seoString}/${parameters.code}.html`;
        case "healthPollenPollenDictionary":
            return "/gesundheit/pollenflug/pollenlexikon/";
        case "healthPollenPollenDictionaryByCharacter":
            return `/gesundheit/pollenflug/pollenlexikon/${parameters.character}/`;
        case "healthPollenPollenDictionaryDetail":
            return `/gesundheit/pollenflug/pollenlexikon/${parameters.character}/${parameters.polle}/`;
        case "healthColdWeather":
            return "/gesundheit/erkaeltungswetter/";
        case "healthBioWeather":
            return `/gesundheit/biowetter/?offset=${parameters.daysOffset.toString()}`;
        case "livecamLandingPage":
            return "/hd-live-webcams/";
        case "livecamInformationPage":
            return "/hd-live-webcams/informationen/";
        case "livecamDetailPage":
            return `/hd-live-webcams/${parameters.seoString}/${parameters.id}/`;
        case "news":
        case "article":
            return `/news/${getArticleSeoString(
                parameters.title,
                parameters.seoUrl,
                parameters.id
            )}`;
        case "blog":
            return `/blog/${getArticleSeoString(
                parameters.title,
                parameters.seoUrl,
                parameters.id
            )}`;
        case "dictionary":
            return `/wetterlexikon/`;
        case "magazine":
            return `/magazin/${getArticleSeoString(
                parameters.title,
                parameters.seoUrl,
                parameters.id
            )}`;
        case "video":
            return `/videos/${getVideoSeoString(
                parameters.title,
                parameters.seoUrl,
                parameters.channel,
                parameters.id
            )}`;
        case "warning": {
            const country: string =
                countrySlugMap[parameters.countryCode] ??
                (countrySlugMap.de as string);
            if (!parameters.title || !parameters.id) {
                return `/unwetterwarnungen/${country}/`;
            }

            return `/unwetterwarnungen/${country}/unwetterwarnungen-${parameters.title}-${parameters.id}.html`;
        }
        case "searchResults":
            return `/suche/${
                parameters.searchTerm
                    ? `?q=${encodeURI(parameters.searchTerm)}`
                    : ""
            }`;
        case "climateTable":
            return `/reise/klima/klimatabelle/${parameters.slug}`;
        case "icyIndex":
            return "/reise/glaetteindex/";
        case "astro":
            switch (parameters.astroType) {
                case "sun":
                    return `/astro/sonne/${parameters.seoString}/${parameters.code}`;
                case "moon":
                    return `/astro/mond/${parameters.seoString}/${parameters.code}`;
            }
        case "uvIndex":
            return `/uv-index/${parameters.seoString}/${parameters.code}/`;
        case "maps":
            return "/wetterkarten/";
        case "rainRadarLocation":
            return `/regenradar/${parameters.seoString}/${parameters.code}.html`;
        case "precipitationForecast":
            return `/wetter_aktuell/niederschlagsprognose/?product=radar-forecast${parameters.geoHash}`;
        case "mapTemperature":
            return `/wetterkarten/temperaturentwicklung/${parameters.geoHash}`;
        case "bbqWeather":
            return `/freizeit/grillwetter/${parameters.seoString}/${parameters.code}.html?offset=${parameters.daysOffset.toString()}`;
        case "tyreWeather":
            return "/freizeit/reifenwetter/";
        case "notificationSettings":
            return "/push/einstellungen/";
        case "imprint":
            return "/impressum/";
        case "privacy":
            return "/datenschutz/adsb/";
        case "nativeAppRadarUrl":
            return `https://app.wttr.io/maps/radar/coordinates/${parameters.latitude},${parameters.longitude}/label/${parameters.label}/locationid/${parameters.skiAreaId}/type/${parameters.type}`;
        case "any":
            return parameters.path;
    }
};

const getRoutePL = (
    parameters: PageParameters
    //searchParameters?: Record<string, string>
): string => {
    switch (parameters.pageName) {
        case "compliance":
            return "/pogoda24/compliance/";
        case "home":
            return "/pogoda24/";
        case "forecastMultiple":
            return `/pogoda24/prognoza-${parameters.daysMultiple.toString()}-dni/${parameters.seoString}/${parameters.code}/`;
        case "forecastWeekend":
            return `/pogoda24/prognoza-weekendowa/${parameters.seoString}/${parameters.code}/`;
        case "forecastToday":
            return `/pogoda24/prognoza-dzisiaj/${parameters.seoString}/${parameters.code}/`;
        case "imprint":
            return "/pogoda24/nota-prawna/";
        case "privacy":
            return "/pogoda24/prywatnosc-i-ciasteczka/ogolnie/";
        default:
            return "/pogoda24/";
    }
};

const getArticleSeoString = (
    title: Nullable<string>,
    seoUrl: string | undefined,
    id: Nullable<string> | undefined
): string => {
    if (title === null) {
        return "";
    }
    if (title || (seoUrl && id)) {
        return `${seoUrl ?? title}_aid_${id ?? ""}.html`;
    }

    return "";
};

const getVideoSeoString = (
    title: Nullable<string> | undefined,
    seoUrl: string | undefined,
    channel: Nullable<string> | undefined,
    id: Nullable<string> | undefined
): string => {
    const newTitle = seoUrl ? seoUrl : title;

    if (id && channel && channel === "timelapse") {
        return `zeitraffer/${id}`;
    }

    if (channel === null && newTitle === null) {
        // used for videos landing page
        return "";
    } else if (channel && newTitle === null) {
        return `${channel}/`;
    } else if (channel && newTitle && id) {
        return `${channel}/${newTitle}/${id}`;
    } else if (newTitle && id) {
        return `${newTitle}/${id}`;
    }

    return "";
};

const countrySlugMap: Record<string, string> = {
    de: "deutschland",
    at: "oesterreich",
    ch: "schweiz",
};
