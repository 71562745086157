"use client";

import { useEffect, useState } from "react";
import type { SliderContext } from ".";
import type { Nullable } from "../../@types/generic";
import { cn } from "../../helpers/classNames";
import { getWindow } from "../../helpers/getWindow";
import { useIntersectionObserver } from "../../hooks/useIntersectionObserver";
import { sendError } from "../ErrorBoundary/helpers";
import styles from "./arrow.module.scss";

type Direction = "left" | "right";

type Props = {
    direction: Direction;
    context: SliderContext;
    sliderRef: React.RefObject<Nullable<HTMLDivElement>>;
};

export const Arrow: React.FC<Props> = ({ direction, sliderRef, context }) => {
    const scrollContainer = sliderRef.current;
    const referenceItem =
        direction === "left"
            ? scrollContainer?.firstChild
            : scrollContainer?.lastChild;
    const observerEntry = useIntersectionObserver(
        referenceItem as HTMLElement,
        {
            threshold: 0.99,
        }
    );
    const [isHidden, setIsHidden] = useState(true);
    const stepWidth =
        context === "topLocations" || context === "miamap" ? 150 : 50;
    const [enableArrow, setEnableArrow] = useState(false);
    const window = getWindow();

    useEffect(() => {
        setIsHidden(() => !!observerEntry?.isIntersecting);

        if (observerEntry && sliderRef.current) {
            const boundingBox = sliderRef.current.getBoundingClientRect();
            setEnableArrow(
                boundingBox.top >= 0 &&
                    boundingBox.bottom <=
                        (window?.innerHeight ||
                            document.documentElement.clientHeight)
            );
        } else {
            setEnableArrow(false);
        }
    }, [enableArrow, observerEntry, sliderRef, window]);

    try {
        if (!enableArrow) {
            return null;
        }

        const onClickArrow = (direction: Direction): void => {
            if (scrollContainer) {
                // eslint-disable-next-line react-compiler/react-compiler
                scrollContainer.scrollLeft +=
                    direction === "left" ? -stepWidth : stepWidth;
            }
        };
        return (
            <>
                <div
                    className={cn(
                        styles.arrow,
                        context === "topLocations" && styles.arrowTopLocations,
                        context === "forecastNavigation" &&
                            styles.arrowForecastNavigation,
                        context === "miamap" && styles.arrowMiaMap,
                        context === "topLocations" &&
                            direction === "left" &&
                            styles.leftTopLocations,
                        context === "topLocations" &&
                            direction === "right" &&
                            styles.rightTopLocations,
                        context === "forecastNavigation" &&
                            direction === "left" &&
                            styles.leftForecastNavigation,
                        context === "forecastNavigation" &&
                            direction === "right" &&
                            styles.rightForecastNavigation,
                        context === "miamap" &&
                            direction === "left" &&
                            styles.leftMiaMap,
                        context === "miamap" &&
                            direction === "right" &&
                            styles.rightMiaMap,
                        isHidden && styles.hidden
                    )}
                    role="button"
                    tabIndex={0}
                    onClick={(): void => onClickArrow(direction)}
                >
                    <span className="icon-dropdown" />
                </div>
            </>
        );
    } catch (e) {
        sendError(e);
        return null;
    }
};
