import React from "react";
import { replacePatterns } from "./helpers";
import { ImageTag } from "./tag";
import type { ImageProps, ImageSizes } from "./types";

export const IMAGE_SIZES = {
    "170x94": { width: "170", height: "94" },
    "260x175": { width: "260", height: "175" },
    "270x90": { width: "270", height: "90" }, // 3:1
    "240x180": { width: "240", height: "180" }, // 4:3
    "280x210": { width: "280", height: "210" }, // 4:3
    "300x150": { width: "300", height: "150" }, // 2:1
    "300x250": { width: "300", height: "250" }, // 6:5 format for content boxes / sales boxes
    default: { width: "280", height: "210" }, // 4:3
};

/**
 * width and height is used together objectFit=fill overrides width+height settings and will stretch the image full into the container,
 * like at "topnews" on the home page. This might lose the aspect ratio of the image
 */
export const Image: React.FC<ImageProps & { imageSizes?: ImageSizes }> = ({
    src,
    alt,
    title,
    width,
    height,
    objectFit,
    additionalSrc,
    className,
    imgTagSize,
    imageSizes,
    preload = false,
    lazy = true,
}) => {
    try {
        if (imageSizes) {
            const lastImageSize = imageSizes.slice(-1)[0];
            const singleSize: ImageSizes = [lastImageSize ?? "default"];
            src = replacePatterns(src, singleSize);

            if (additionalSrc) {
                additionalSrc = replacePatterns(additionalSrc, imageSizes);
            }
        }

        return additionalSrc ? (
            <picture>
                <source srcSet={additionalSrc || src} type="image/webp" />
                <ImageTag
                    src={src}
                    alt={alt}
                    title={title}
                    lazy={preload ? false : lazy}
                    className={className}
                    width={width}
                    height={height}
                    imgTagSize={imgTagSize}
                    objectFit={objectFit}
                    preload={preload}
                />
            </picture>
        ) : (
            <ImageTag
                src={src}
                alt={alt}
                title={title}
                lazy={preload ? false : lazy}
                className={className}
                width={width}
                height={height}
                imgTagSize={imgTagSize}
                objectFit={objectFit}
                preload={preload}
            />
        );
    } catch {
        return null;
    }
};
