import type { CountryType, Nullable } from "../../../@types/generic";
import { fetcher } from "../index";
import type { SearchResult } from "../types/search";

export const getAutosuggestSearch = (
    searchTerm: string,
    country: CountryType
): Promise<Nullable<SearchResult>> =>
    fetcher<SearchResult>(
        { path: `/search/autosuggest/${searchTerm}/` },
        { country }
    ).catch(() => null);
