import { IMAGE_SIZES } from "./index";
import type { ImageSizes } from "./types";

export const replacePatterns = (src: string, sizes: ImageSizes): string => {
    if (sizes.length === 0) {
        return src
            .replace(/<<WIDTH>>/g, IMAGE_SIZES.default.width)
            .replace(/<<HEIGHT>>/g, IMAGE_SIZES.default.height);
    }
    const allowedSizes = Object.keys(IMAGE_SIZES);
    // check to replace only allowed images sizes
    if (!sizes.every((size) => allowedSizes.includes(size))) {
        return src;
    }

    const srcParts = src.split(", ");
    if (srcParts.length === sizes.length) {
        return srcParts
            .map((part, index) => {
                const size = sizes[index];
                if (size) {
                    return part
                        .replace(/<<WIDTH>>/g, IMAGE_SIZES[size].width)
                        .replace(/<<HEIGHT>>/g, IMAGE_SIZES[size].height);
                }
                return part
                    .replace(/<<WIDTH>>/g, IMAGE_SIZES.default.width)
                    .replace(/<<HEIGHT>>/g, IMAGE_SIZES.default.height);
            })
            .join(", ");
    }
    throw new Error("sizes[] and src parts length mismatch");
};
