"use client";

import dynamic from "next/dynamic";
import React from "react";
import { sendError } from "../ErrorBoundary/helpers";

type Props = {
    children: React.ReactNode;
};

// @see: https://blog.bitsrc.io/using-non-ssr-friendly-components-with-next-js-916f38e8992c
const ClientOnly: React.FC<Props> = ({ children }) => {
    try {
        return <>{children}</>;
    } catch (e) {
        sendError(e);
        return null;
    }
};

export default dynamic(() => Promise.resolve(ClientOnly), {
    ssr: false,
});
