import Image from "next/image";
import React from "react";
import { sendError } from "../ErrorBoundary/helpers";
import type { ImageProps } from "./types";

export const ImageTag: React.FC<ImageProps> = React.memo(
    ({
        className,
        src,
        alt,
        width,
        height,
        imgTagSize,
        title,
        lazy,
        objectFit,
        preload,
    }) => {
        try {
            return (
                <Image
                    className={className ?? undefined}
                    src={src}
                    alt={alt}
                    style={{
                        height: height ?? "100%",
                        width: width ?? "100%",
                        objectFit: objectFit ?? "fill",
                    }}
                    width={imgTagSize?.width ?? 1} // needed for seo, correct values set by css
                    height={imgTagSize?.height ?? 1} // needed for seo, correct values set by css
                    title={title}
                    loading={lazy ? "lazy" : "eager"}
                    unoptimized
                    priority={preload}
                    fetchPriority={preload ? "high" : undefined}
                />
            );
        } catch (e) {
            sendError(e);
            return null;
        }
    }
);

ImageTag.displayName = "ImageTag";
