import { usePathname, useSearchParams } from "next/navigation";
import { useEffect, useRef, useState } from "react";
import type { Nullable } from "../@types/generic";
import { getWindow } from "../helpers/getWindow";

export const useIsPageChange = (): boolean => {
    const window = getWindow();
    const pathname = usePathname();
    const searchParams = useSearchParams();
    const currentPathname = useRef<Nullable<string | undefined>>(pathname);
    const [isPageChange, setIsPageChange] = useState(false);
    const page = searchParams.get("page");
    const [currentPaginationPage, setCurrentPaginationPage] = useState(
        typeof page === "string" && page !== "" ? parseInt(page) : null
    );

    useEffect(() => {
        const newPathname = pathname;
        const newPage = searchParams.get("page");
        const newPaginationPage =
            typeof newPage === "string" ? parseInt(newPage) : null;
        if (currentPathname.current !== newPathname) {
            setIsPageChange(true);
            currentPathname.current = newPathname;
        } else if (newPaginationPage !== currentPaginationPage) {
            setIsPageChange(true);
            setCurrentPaginationPage(newPaginationPage);
        } else {
            setIsPageChange(false);
        }
    }, [
        // use window.location.pathname to retrigger hook for navigations for the same template e.g. forcast/mulitple
        window?.location.pathname,
        currentPaginationPage,
        pathname,
        searchParams,
    ]);

    return isPageChange;
};
