import type { SWRResponse } from "swr";
import useSWR from "swr";
import type { Nullable } from "../../../@types/generic";
import {
    useCountry,
    usePageType,
} from "../../../components/ApplicationState/hooks";
import { isDachProduct } from "../../../helpers/isDachProduct";
import { QUERY_NAME_AUTOSUGGEST_SEARCH } from "../../cacheKeys";
import { getAutosuggestSearch } from "../api/search";
import type { SearchResult } from "../types/search";

export const useAutosuggestSearch = (
    searchTerm: string
): SWRResponse<Nullable<SearchResult>> => {
    const country = useCountry();
    const pageType = usePageType();

    const result = useSWR<Nullable<SearchResult>>(
        !!searchTerm ? [QUERY_NAME_AUTOSUGGEST_SEARCH, searchTerm] : null,
        () => getAutosuggestSearch(searchTerm, country)
    );
    const { data, isLoading, ...rest } = result;
    let sortedData: SearchResult = {
        locations: [],
        videos: [],
        touristicRegions: [],
        skiAreas: [],
        livecams: [],
    };

    if (data) {
        if (!isDachProduct(country)) {
            sortedData.locations = data.locations.map((item) => ({
                ...item,
                type: "locations",
            }));
        } else if (pageType?.isAnySkiAreasPage) {
            sortedData = {
                skiAreas: data.skiAreas.map((item) => ({
                    ...item,
                    type: "skiAreas",
                })),
                locations: data.locations.map((item) => ({
                    ...item,
                    type: "locations",
                })),
                touristicRegions: data.touristicRegions.map((item) => ({
                    ...item,
                    type: "touristicRegions",
                })),
                videos: data.videos.map((item) => ({
                    ...item,
                    type: "videos",
                })),
                livecams: data.livecams.map((item) => ({
                    ...item,
                    type: "livecams",
                })),
            };
        } else {
            sortedData = {
                locations: data.locations.map((item) => ({
                    ...item,
                    type: "locations",
                })),
                touristicRegions: data.touristicRegions.map((item) => ({
                    ...item,
                    type: "touristicRegions",
                })),
                videos: data.videos.map((item) => ({
                    ...item,
                    type: "videos",
                })),
                skiAreas: data.skiAreas.map((item) => ({
                    ...item,
                    type: "skiAreas",
                })),
                livecams: data.livecams.map((item) => ({
                    ...item,
                    type: "livecams",
                })),
            };
        }
    }
    return {
        data: sortedData,
        isLoading: isLoading || data === undefined,
        ...rest,
    };
};
