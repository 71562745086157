// eslint-disable-next-line no-restricted-imports
import dayjs, { type Dayjs as DayjsType } from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import isToday from "dayjs/plugin/isToday";
import isTomorrow from "dayjs/plugin/isTomorrow";
import tz from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import weekday from "dayjs/plugin/weekday";
import type { CountryType, Nullable } from "../../@types/generic";

const fallbackTimezones = {
    de: "Europe/Berlin",
    at: "Europe/Vienna",
    ch: "Europe/Zurich",
    pl: "Europe/Warsaw",
};

const dayjsExtended = dayjs;
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(isToday);
dayjs.extend(isTomorrow);
dayjs.extend(isBetween);
dayjs.extend(weekday);

export const getTimezone = (
    timezone: Nullable<string> | undefined,
    country: CountryType
): string => {
    return timezone ?? fallbackTimezones[country];
};

export default dayjsExtended;

export type Dayjs = DayjsType;
